import {
  Select,
  OptionBase,
  ChakraStylesConfig,
  chakraComponents,
  SelectComponentsConfig as ChakraSelectComponentsConfig,
  GroupBase,
  MultiValue,
  SingleValue,
  InputActionMeta,
} from 'chakra-react-select';
import { FC, ReactNode } from 'react';

import { Button } from 'quotient';

// https://www.w3.org/WAI/ARIA/apg/patterns/combobox/

export type SelectOption = OptionBase & {
  value: string;
  label: ReactNode;
};

export type SelectComponentsConfig = ChakraSelectComponentsConfig<SelectOption, boolean, GroupBase<SelectOption>>;

export type ComboboxProps = {
  options: SelectOption[];
} & {
  isMulti: boolean;
  value: MultiValue<SelectOption> | SingleValue<SelectOption>;
  onChange: (newValue: any, actionMeta: any) => void;
  isLoading?: boolean;
  noOptionsMessage?: (obj: { inputValue: string }) => string;
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  placeholder?: string;
  dataTestId?: string;
  includeDropdownIndicator?: boolean;
  components?: SelectComponentsConfig;
  isClearable?: boolean;
  isDisabled?: boolean;
};
const chakraStyles: ChakraStylesConfig<SelectOption, boolean, GroupBase<SelectOption>> = {
  container: (provided) => ({
    ...provided,
    borderRadius: '4px',
    border: '1px solid',
    borderColor: 'gray.300',
    backgroundColor: 'primaryNeutral.white',
  }),
  control: (provided) => ({
    ...provided,
    borderRadius: '4px',
    border: 'none',
    boxShadow: 'none',
    padding: '2px',
    h: 'auto',
  }),
  input: (provided) => ({
    ...provided,
    textStyle: 'body',
    color: 'gray.800',
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? 'blue.500' : 'primaryNeutral.white',
    color: state.isSelected ? 'primaryNeutral.white' : 'gray.800',
    _hover: {
      backgroundColor: state.isSelected ? 'blue.600' : 'gray.100',
    },
    padding: '8px 12px',
  }),
  menu: (provided) => ({
    ...provided,
    boxShadow: 'md',
    borderRadius: '4px',
    borderColor: 'gray.300',
    marginTop: '4px',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: '4px',
    borderColor: 'gray.300',
  }),
  clearIndicator: (provided) => ({
    ...provided,
    color: 'primaryNeutral.500',
    backgroundColor: 'transparent',
    _hover: {
      color: 'primaryNeutral.900',
      backgroundColor: 'transparent',
    },
    width: 'auto',
    height: 'auto',
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
};
const customComponents: SelectComponentsConfig = {
  ClearIndicator: (props: any) => {
    return props.isMulti ? (
      <chakraComponents.ClearIndicator {...props}>
        <Button size="sm" unmask variant="ghost">
          Clear all
        </Button>
      </chakraComponents.ClearIndicator>
    ) : (
      <chakraComponents.ClearIndicator {...props} />
    );
  },
};
export const QuotientCombobox: FC<ComboboxProps> = ({
  options,
  value,
  onChange,
  onInputChange,
  isMulti,
  isClearable,
  isDisabled = false,
  dataTestId,
  placeholder,
  noOptionsMessage,
  isLoading,
  components = {},
  includeDropdownIndicator = false,
}) => {
  // modifying chakraStyles makes QuotientCombobox impure, so scoping styles
  // here to prevent impurities. If you need to modify a style dynamically, do it like this.
  const styles = { ...chakraStyles };
  if (includeDropdownIndicator) {
    styles.dropdownIndicator = (provided) => {
      return { ...provided, background: 'none' };
    };
  }

  return (
    <Select
      chakraStyles={styles}
      components={{ ...customComponents, ...components }}
      data-testid={dataTestId}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isLoading={isLoading}
      isMulti={Boolean(isMulti)}
      noOptionsMessage={noOptionsMessage}
      options={options}
      placeholder={placeholder}
      value={value}
      onChange={onChange as any}
      onInputChange={onInputChange}
    />
  );
};
