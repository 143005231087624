import { useMutation, UseMutationOptions, useQueryClient, useQuery, UseQueryOptions } from 'react-query';

import { getRequest, postRequest } from 'services/apiRequests';
import type { APIList, StringifiedUUID } from 'types';
import { AccountDealerPayload, AccountDealerAsyncResponse } from 'types/accountDealer';
import { APIError } from 'utils/errors';

const apiUrl = (modelId: StringifiedUUID) => `/api/v1/tq/hierarchy-models/${modelId}/account-dealer/`;
const asyncApiUrl = (modelId: StringifiedUUID) => `/api/v1/tq/hierarchy-models/${modelId}/account-dealer-async/`;

const postAccountDealer = (modelId: StringifiedUUID, csrfToken: string) => (payload: AccountDealerPayload) => {
  return postRequest<AccountDealerPayload, APIList<AccountDealerPayload>>(apiUrl(modelId), payload, csrfToken);
};
const postAccountDealerAsync = (modelId: StringifiedUUID, csrfToken: string) => (payload: AccountDealerPayload) => {
  return postRequest<AccountDealerPayload, AccountDealerAsyncResponse>(asyncApiUrl(modelId), payload, csrfToken);
};

const useAccountDealerMutation = (
  modelId: StringifiedUUID,
  csrfToken: string,
  options: UseMutationOptions<APIList<AccountDealerPayload>, APIError, AccountDealerPayload> = {},
) => {
  return {
    mutation: useMutation<APIList<any>, APIError, AccountDealerPayload>(postAccountDealer(modelId, csrfToken), options),
  };
};
const useAccountDealerAsyncMutation = (
  modelId: StringifiedUUID,
  csrfToken: string,
  options: UseMutationOptions<AccountDealerAsyncResponse, APIError, AccountDealerPayload> = {},
) => {
  return {
    mutation: useMutation<AccountDealerAsyncResponse, APIError, AccountDealerPayload>(
      postAccountDealerAsync(modelId, csrfToken),
      options,
    ),
  };
};

const getApiUrl = (modelId: StringifiedUUID, dataObjectId: StringifiedUUID) =>
  `/api/v1/tq/hierarchy-models/${modelId}/objects/${dataObjectId}/account-dealer-configuration/`;

const getAccountDealerConfiguration = (modelId: StringifiedUUID, territoryId: StringifiedUUID) =>
  getRequest<null, AccountDealerPayload>(getApiUrl(modelId, territoryId));

const useGetAccountDealerConfiguration = (
  options: UseQueryOptions<AccountDealerPayload, APIError> = {},
  modelId: StringifiedUUID,
  dataObjectId: StringifiedUUID,
) => {
  const queryClient = useQueryClient();
  const QUERY_KEY = ['hierarchy-models', modelId, 'account-dealer-configuration', dataObjectId];
  const query = useQuery<AccountDealerPayload, APIError>(
    QUERY_KEY,
    () => getAccountDealerConfiguration(modelId, dataObjectId),
    {
      ...options,
    },
  );

  const invalidateQuery = () => queryClient.invalidateQueries(QUERY_KEY);

  return {
    query,
    invalidateQuery,
  };
};

export const AccountDealerService = {
  useAccountDealerMutation,
  useAccountDealerAsyncMutation,
  useGetAccountDealerConfiguration,
};
