import {
  Alert as ChakraAlert,
  AlertDescription as ChakraAlertDescription,
  AlertTitle as ChakraAlertTitle,
  Flex,
  LayoutProps,
} from '@chakra-ui/react';
import type { ComponentStyleConfig } from '@chakra-ui/theme';
import { CSSProperties, ReactNode } from 'react';

import { Button, CloseButton as ChakraCloseButton } from 'quotient';
import { QuotientButtonVariant } from 'quotient/theme/components/Buttons/Button';
import { SelectedStyledSystemProps } from 'quotient/types';

export const AlertStyle: ComponentStyleConfig = {
  baseStyle: {
    container: {
      borderRadius: '6px',
      paddingX: '16px',
      paddingY: '12px',
    },
    title: {
      textStyle: 'bodySemiBold',
      fontWeight: 600,
      color: 'primaryNeutral.900',
    },
    description: {
      textStyle: 'bodyRegular',
      color: 'primaryNeutral.900',
    },
  },
  variants: {
    subtle: (props) => {
      const { colorScheme: c } = props;
      let color;
      if (c === 'red') color = 'danger.50';
      if (c === 'green') color = 'positive.50';
      if (c === 'orange') color = 'warning.50';
      if (c === 'blue') color = 'primary.100';
      return {
        container: {
          bg: color,
        },
        icon: {
          color: 'primaryNeutral.900',
        },
      };
    },
  },
};

export type QuotientAlertProps = {
  title?: ReactNode;
  description: ReactNode;
  isClosable?: boolean;
  onClick?: () => void;
  onClose?: () => void;
  status: 'error' | 'success' | 'warning' | 'info';
  icon: JSX.Element;
  iconAlign?: CSSProperties['alignItems'];
  href?: string;
  openInNewTab?: boolean;
  hrefButtonText?: string;
  buttonTestId?: string;
  buttonVariant?: QuotientButtonVariant;
  useNavButtons?: boolean;
  rightContent?: ReactNode;
} & SelectedStyledSystemProps &
  LayoutProps;

export type AlertNavigationProps = {
  buttonTestId?: string;
  href?: string;
  openInNewTab?: boolean;
  hrefButtonText?: string;
  onClick?: () => void;
  onDismiss?: () => void;
  buttonVariant?: QuotientButtonVariant;
};

export const AlertNavigation = ({
  buttonTestId,
  href,
  hrefButtonText,
  onClick,
  onDismiss,
  openInNewTab = false,
  buttonVariant = 'outline',
}: AlertNavigationProps) => {
  return (
    <Flex alignItems="center">
      {onDismiss && (
        <Button unmask variant="ghost" onClick={onDismiss}>
          Dismiss
        </Button>
      )}

      {href ? (
        <Button
          data-testid={buttonTestId}
          href={href as string}
          openInNewTab={openInNewTab}
          unmask
          variant={buttonVariant}
        >
          {hrefButtonText}
        </Button>
      ) : (
        onClick && (
          <Button data-testid={buttonTestId} unmask variant={buttonVariant} onClick={onClick}>
            {hrefButtonText}
          </Button>
        )
      )}
    </Flex>
  );
};

export const QuotientAlert = ({
  title,
  description,
  isClosable = true,
  onClick,
  onClose,
  status,
  icon,
  iconAlign = 'center',
  href,
  hrefButtonText,
  rightContent,
  buttonTestId,
  buttonVariant = 'outline',
  useNavButtons = false,
  openInNewTab = false,
  ...rest
}: QuotientAlertProps) => {
  return (
    <ChakraAlert {...rest} alignItems="center" justifyContent="space-between" role="alert" status={status}>
      <Flex alignItems={iconAlign}>
        <Flex pr="20px" py="4px">
          {icon}
        </Flex>
        <Flex flexDirection="column">
          {title && <ChakraAlertTitle>{title}</ChakraAlertTitle>}
          <ChakraAlertDescription>{description}</ChakraAlertDescription>
        </Flex>
      </Flex>

      {/* optional dismiss + navigate buttons */}
      {useNavButtons && (
        <AlertNavigation
          buttonTestId={buttonTestId}
          buttonVariant={buttonVariant}
          href={href}
          hrefButtonText={hrefButtonText}
          openInNewTab={openInNewTab}
          onClick={onClick}
          onDismiss={onClose}
        />
      )}
      {rightContent}

      {/* optional x icon */}
      {isClosable && onClose && (
        <Flex alignSelf="flex-start" position="relative">
          <ChakraCloseButton onClose={onClose} />
        </Flex>
      )}
    </ChakraAlert>
  );
};
